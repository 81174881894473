import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { Section, Container } from "../components/global"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import Footer from "../components/sections/footer"

import { Disqus, CommentCount } from "gatsby-plugin-disqus"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { site, markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  const seoTitle = `${frontmatter.title} | Blog`
  const image = frontmatter.thumbnail
    ? frontmatter.thumbnail.childImageSharp.resize
    : null

  let disqusConfig = {
    url: `${site.siteMetadata.siteUrl + frontmatter.pathname}`,
    title: seoTitle,
  }

  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={frontmatter.description}
        pathname={frontmatter.path}
        image={image}
        article={true}
        date={frontmatter.dateS}
        keywords={[frontmatter.keywords]}
      ></SEO>
      <Navigation />
      <Section id="top">
        <StyledContainer>
          <div className="blog-post-container">
            <div className="blog-post">
              <h1>{frontmatter.title}</h1>
              <div>
                <CommentCount config={disqusConfig} />
              </div>
              <span>publicado em {frontmatter.date}</span>
              <StyledP>{frontmatter.description}</StyledP>
              <PostThumbnailDiv>
                <img
                  src={frontmatter.thumbnail.childImageSharp.resize.src}
                  alt={frontmatter.title}
                />
              </PostThumbnailDiv>
              <div
                className="blog-post-content"
                dangerouslySetInnerHTML={{ __html: html }}
              />
              <Disqus config={disqusConfig} />
            </div>
          </div>
        </StyledContainer>
      </Section>
      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      excerpt(pruneLength: 160)
      frontmatter {
        date: date(formatString: "DD/MM/YY")
        dateS: date(formatString: "YYYY-MM-DD")
        path
        title
        description
        keywords
        thumbnail {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
    }
  }
`

const StyledContainer = styled(Container)`
  width: 60%;
  max-width: 670px;
  margin: 0px auto;
  padding-top: 10vh;

  text-align: justify;

  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 100%;
    padding-top: 5vh;
  }

  h1,
  h2,
  h3,
  h4 {
    text-align: start;
  }

  p {
    margin-block-end: 2em;
    font-family: HK Grotesk Normal;
    font-size: 22px;
    line-height: 30px;
    text-indent: initial;

    @media (max-width: ${props => props.theme.screen.sm}) {
      margin-block-end: 1.5em;
      font-size: 20px;
      line-height: 26px;
    }

    em {
      padding-left: 10%;
      font-family: HK Grotesk Light;
      font-size: 14px;
      line-height: 14px;

      @media (max-width: ${props => props.theme.screen.sm}) {
        padding-left: 0;
      }
    }
  }

  blockquote {
    font-style: italic;
  }

  a {
    color: ${props => props.theme.color.accent};
  }

  ul {
    list-style: circle;
    font-family: HK Grotesk Normal;
    font-size: 17px;
    line-height: 26px;
    margin-block-end: 2em;
  }

  span {
    ${props => props.theme.font_size.xxxsmall};
    color: ${props => props.theme.color.white.darker};
  }

  img {
    display: block;
    width: 80%;
    margin: auto;

    @media (max-width: ${props => props.theme.screen.sm}) {
      width: 100%;
    }
  }
`

const StyledP = styled.p`
  margin-top: 4px;
`

const PostThumbnailDiv = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
  text-align: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  margin: 24px 0;
`
